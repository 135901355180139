import React, { useState, useEffect, useRef } from "react";
import styles from "../../css/Product.module.css";
import Header from "../../home/Header";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { Button } from "react-bootstrap";
import axiosConfig from "../../services/axiosConfig";
import { FaDownload } from 'react-icons/fa'; // For FaDownload icon
import { IoMdRefresh } from 'react-icons/io'; // For IoMdRefresh icon
import { FaArrowLeft } from 'react-icons/fa';

function ProductForm() {
  const [productName, setProductName] = useState("");
  const [type, setType] = useState("");
  const [partNo, setPartNo] = useState("");
  const [hsnNo, setHsnNo] = useState("");
  const [unit, setUnit] = useState("");
  const [modelNo, setModelNo] = useState("");
  const [hsnNoError, setHsnNoError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [modelNoError, setModelNoError] = useState("");
  const [error, setError] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;

  
const [resetFields, setResetFields] = useState(false);
 
const saveProduct = async () => {
  console.log({ productName, type, partNo, hsnNo, unit, modelNo });  // Log field values

  if (!productName || !type  || !hsnNo || !unit || !partNo ||!modelNo) {
    alert("Please fill in all the required fields");
    return;
  }

  try {
    const payload = {
      product_name: productName,
      type: type,
       part_no: partNo,
      hsn_no: hsnNo,
      unit: unit,
      model_no: modelNo,
    };

    console.log("Payload: ", payload);  // Log the payload for debugging

    if (params.mode === "edit") {
      console.log("Editing product...");  // Debug log
      await axiosConfig.put(`/products/${params.productId}`, payload);
    } else {
      console.log("Adding new product...");  // Debug log
      await axiosConfig.post("/products", payload);
    }
  } catch (error) {
    console.error("Error for saving payload:", error);
  }

  setOpenDialog(true);
};


  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/ProductManagement");
  };
  useEffect(() => {
    if (resetFields) {
      setProductName("");
      setType("");
       setPartNo("");
      setHsnNo("");
      setUnit("");
      setModelNo("")
      
      setResetFields(false);  
    }
  }, [resetFields]); 
const handleResetProduct = () => {
  setResetFields(true);  // Trigger reset
};
  // Load existing product details if in edit mode
  const loadProductDetails = async () => {
    if (params.mode === "edit") {
     
      try {
        const response = await axiosConfig.get(`/products/${params.productId}`, {
         
        });

        const productDetails = response.data;
        setProductName(productDetails.product_name);
        setType(productDetails.type);
         setPartNo(productDetails.part_no);
        setHsnNo(productDetails.hsn_no);
        setUnit(productDetails.unit);
        console.log("Loaded Product Details: ", productDetails); 
        setModelNo(productDetails.model_no)
      } catch (error) {
        if (axiosConfig.isCancel(error)) {
          console.log("Request canceled");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    }
  };

 

  useEffect(() => {
    loadProductDetails();
  
  }, []);

  return (
    <form>
      <Header />
      <div className={styles["button-container"]}>
      <button
  className={styles["button-backArrow"]} // Apply styling to the button
  onClick={() => navigate('/ProductManagement')} // Navigate on click
>
  <div className={styles["circle-container"]}>
    <FaArrowLeft size={14} /> {/* Render the back arrow icon */}
  </div>
  <span className={styles["back-text"]}>Back</span> {/* Add text after the icon */}
</button>
</div>
      <h2 className={styles["basic-info-title"]}>
        {params.mode === "edit" ? "Edit Product" : "Add Product"}
      </h2>

      <div className={styles["product-container"]}>
        <div className={styles["basic-info-row"]}>
          <label className={styles["basic-info-label"]}>
            Product Name<span className={styles["required"]}>*</span>
          </label>
          <input
            type="text"
            id="productName"
            value={productName}
            className={styles["basic-info-input"]}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Search product name"
          />
        </div>

        <div className={styles["basic-info-row"]}>
          <label className={styles["basic-info-label"]}>Type</label>
          <input
            type="text"
            id="type"
            value={type}
            className={styles["basic-info-input"]}
            onChange={(e) => setType(e.target.value)}
            placeholder="Enter type"
          />
        </div>

     {/* HSN No */}
<div className={styles["basic-info-row"]}>
  <label className={styles["basic-info-label"]} htmlFor="hsnNo">
    HSN No
  </label>
  <input
    type="text"
    id="hsnNo"
    value={hsnNo}
    className={`${styles["basic-info-input"]} ${hsnNoError ? styles["input-error"] : ""}`}
    onChange={(e) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        setHsnNo(newValue);
        setHsnNoError(""); // Clear error if valid input
      } else {
        setHsnNoError("Only numeric values are allowed");
      }
    }}
    placeholder="Enter HSN No"
  />
  {hsnNoError && <p className={styles["herror-message"]}>{hsnNoError}</p>}
</div>

{/* Unit */}
<div className={styles["basic-info-row"]}>
  <label className={styles["basic-info-label"]} htmlFor="unit">
    Unit
  </label>
  <input
    type="text"
    id="unit"
    value={unit}
    className={`${styles["basic-info-input"]} ${unitError ? styles["input-error"] : ""}`}
    onChange={(e) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        setUnit(newValue);
        setUnitError(""); // Clear error if valid input
      } else {
        setUnitError("Only numeric values are allowed");
      }
    }}
    placeholder="Enter unit"
  />
  {unitError && <p className={styles["uerror-message"]}>{unitError}</p>}
</div>
<div className={styles["basic-info-row"]}>
  <label className={styles["basic-info-label"]}>Part Number</label>
  <input
    type="text"
    id="partNo"
    value={partNo} // Make sure partNo state is defined
    className={styles["basic-info-input"]}
    onChange={(e) => setPartNo(e.target.value)} // Set the state for partNo
    placeholder="Enter part number"
  />
</div>

{/* Model No */}
<div className={styles["basic-info-row"]}>
  <label className={styles["basic-info-label"]} htmlFor="modelNo">
    Model No
  </label>
  <input
    type="text"
    id="modelNo"
    value={modelNo}
    className={`${styles["basic-info-input"]} ${modelNoError ? styles["input-error"] : ""}`}
    onChange={(e) => {
      const newValue = e.target.value;
      if (/^\d*$/.test(newValue)) {
        setModelNo(newValue);
        setModelNoError(""); // Clear error if valid input
      } else {
        setModelNoError("Only numeric values are allowed");
      }
    }}
    placeholder="Enter model no"
  />
  {modelNoError && <p className={styles["merror-message"]}>{modelNoError}</p>}
</div>



      </div>

      <div className={styles["product-buttons"]}>
        <Button
          variant="primary"
          className={styles["product-primary"]}
          onClick={saveProduct}
        >
          <FaDownload className={styles["productsave-icon"]} />
          {params.mode === "edit" ? "Update" : "Submit"}
        </Button>

        <Button
          variant="primary"
          className={styles["product-reset-btn"]}
          onClick={handleResetProduct}
        >
          <IoMdRefresh className={styles["productreset-icon"]} />
          Reset
        </Button>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          className: styles["product-dialog-paper"],
        }}
      >
        <DialogContent dividers className={styles["product-dialog-content"]}>
          <img
            src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png"
            alt="Success Icon"
            className={styles["product-success-icon"]}
          />
          <span className={styles["productsuccess-message"]}>
            {params.mode === "edit"
              ? "Product has been successfully updated."
              : "Product has been successfully created."}
          </span>
        </DialogContent>
        <DialogActions className={styles["product-dialog-actions"]}>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            className={styles["product-ok-button"]}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default ProductForm;
