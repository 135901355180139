import React, { useState, useEffect,useRef} from "react";
import Header from "../../home/Header";
import Button from "react-bootstrap/Button";
import styles from "../../css/Enquiry.module.css";
import { useNavigate, useLocation } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "react-datepicker/dist/react-datepicker.css";
import { BsTrash3Fill, BsPencilFill, BsSearch ,  BsPlusCircleFill,} from "react-icons/bs";
import { FaDownload } from "react-icons/fa6";
import { IoMdRefresh } from "react-icons/io";
import { FaArrowLeft } from 'react-icons/fa';
import axiosConfig from "../../services/axiosConfig";
import DatePicker from "react-datepicker";
function Enquiry() {
  const navigate = useNavigate();
  const addNavigateButton = () => {
    navigate("/enquiryManagement");
  };
  // const [name,setName] = useState('');
  const [companyname, setcompanyname] = useState("");
  const [segment, setsegment] = useState("");
  const [street, setStreet] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [StreetLine, setStreetLine] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [Source, setSource] = useState("");
  const [Category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [Name, setname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Productenquired, setProductenquired] = useState("");
  const [Equivalentmodel, setEquivalentmodel] = useState("");
  const [Qty, setQty] = useState("");
  const [Expectedprice, setExpectedprice] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState(
    new Date().toISOString().split("T")[0] // Current date in "yyyy-MM-dd" format
  );
  const [NextFollowup, setNextFollowup] = useState(null);
  const [checkboxValue1, setCheckboxValue1] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const location = useLocation();
  const { enquiryID } = location.state || {}; // Get enquiryID from the passed state
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [OrderValue, setOrderValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [Description, setDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [enquiryProducts, setEnquiryProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [EnquiryID, setEnquiryID] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  // const [Total, setTotal] = useState("");
  const [Notes, setNotes] = useState("");
  const [assigned, setAssigned] = useState("");
  const [user_id, setUserId] = useState(0);

  const [Chalanges, setChalanges] = useState("");
  const [Requirement, setRequirement] = useState("");
  const [stages, setStages] = useState("");
  const [winPossibilities, setWinPossibilities] = useState("");
  const [Competitor, setCompetitor] = useState("");
  const [Priority, setPriority] = useState("");
  const [active, setActive] = useState("Open");
  const [companyId, setCompanyId] = useState("");
  const [companyAddressId, setCompanyAddressId] = useState("");
  const [City, setCity] = useState("");
    const [gstin, setGstin] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [Total, setTotal] = useState(0);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [contacts, setContacts] = useState([
    { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
  ]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  // State to manage start date and priority selection
  const [enquiryTitle, setEnquiryTitle] = useState("");
  const [userQuotation, setUserQuotation] = useState("");
  const [StartDate, setStartDate] = useState(null);
  const [EndDate, setEndDate] = useState(null); 
  // const cancelTokenRef = useRef(null);
  const [isEditable, setIsEditable] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);
  const [compName, setCompName] = useState("");
  const [isSubmitting, setSubmitting] = useState(false);
  const [deletedProductIndexes, setDeletedProductIndexes] = useState([]);



  const openModalWithDelay = () => {
    // Show the loader
    setIsLoaderVisible(true);

    // Set a 5-second delay
    setTimeout(() => {
      setIsLoaderVisible(false); // Hide the loader
      setIsModalOpen(true); // Open the modal
    }, 1000);
  };

  useEffect(() => {
    // Load authorized users from localStorage
    const storedUsers = localStorage.getItem("authorizedUsers");
    console.log('storedUsers:', storedUsers);

    if (storedUsers) {
      let parsedUsers;
      
      // Check if the stored data is an array or an object
      if (Array.isArray(JSON.parse(storedUsers))) {
        parsedUsers = JSON.parse(storedUsers);
      } else {
        parsedUsers = [JSON.parse(storedUsers)]; // Convert to array if it's a single object
      }

      setAssignedOptions(parsedUsers);

      // If there's only one user, auto-fill the assigned field
      if (parsedUsers.length === 1) {
        const singleUser = parsedUsers[0];
        setAssigned(singleUser.userName); // Auto-fill the user name
        setUserId(singleUser.userId); // Set the user ID
        setCompName(singleUser.compName); // Set the company name
      }
    } else {
      console.warn("No authorized users data found in localStorage.");
    }
  }, []);

  const handleSelect = (option) => {
    setAssigned(option.userName);
    setUserId(option.userId); // Set the selected userId
    setCompName(option.compName);
    setDropdownOpen(false); // Close the dropdown
  };

  // State to store form data
  const [formData, setFormData] = useState({
    productName: "",
    productId: "", // Store the selected product ID
    modelNo: "",
    qty: 0,
    orderValue: 0,
    description: "",
  });

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCompanies([]);
    } else {
      const filtered = companies.filter((company) =>
        company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  }, [searchTerm, companies]);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setcompanyname(""); // Clear the selected company when typing
    if (term === "") {
      setFilteredCompanies([]);
    } else {
      setFilteredCompanies(
        
        companies.filter((company) =>
          company.company_name.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  };
  // Handle company selection
  const handleCompanySelect = (company) => {
    
    // Set selected company ID and fetch details from the filtered company list
    setSearchTerm(company.company_name);
    setUser(company.category);
    setCompanyId(company.company_id);
    setname(company.contact_details[0]?.name || "");
    setCompanyAddressId(company.address_details?.comp_addr_id || "");
    // setname(company.contact?.name || "");
    setEmailID(company.contact_details[0]?.email || "");
    setContactNumber(company.contact_details[0]?.phone || "");
    setSource(company.address_details?.source || "");
    setCity(company.address_details?.city || "");
    setDoorNo(company.address_details?.door_no || "");
    setstreetname(company.address_details?.street_line_1 || "");
    setStreetLine(company.address_details?.street_line_2 || "");
    setDistrict(company.address_details?.district || "");
    setAreaTerritory(company.territory || "");
    setState(company.address_details?.state || "");
    setPincode(company.address_details?.postal_code || "");
    setsegment(company.segment || "");
    setGstin(company.address_details.gst_pin);

    setContacts(company.contact_details);
  setSelectedContactIndex(0);
   setIsEditable(false);

  };

  const handleContactSelect = (index) => {
    const selectedContact = contacts[index];
    setname(selectedContact?.name || "");
    setEmailID(selectedContact?.email || "");
    setContactNumber(selectedContact?.phone || "");
  };

  const handleChangeUser = (event) => {
    const selectedValue = event.target.value;
    setUser(selectedValue);

    // If "New" is selected, reset all fields
    if (selectedValue === "Ford") {
      setSearchTerm("");
      setcompanyname("");
      setSource("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setCity("");
      setDistrict("");
      setState("");
      setPincode("");
      setContacts([
        { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
      ]); // Reset contacts array
    }
    

  };
  // Hide suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#companySearch")) {
        setFilteredCompanies([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Open modal
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };

 

  const handleProductChange = (product) => {
    setFormData({
      ...formData,
      productName: product.product_name, // Set product name
      productId: product.product_id, // Set product ID
    });
    setIsDropdownOpen(false); // Close the dropdown once a product is selected
  };
  const handleDropdownClick = () => {
    if (!isDropdownOpen) {
      // Load products from localStorage
      const storedProducts = localStorage.getItem("products");
      if (storedProducts) {
        const parsedProducts = JSON.parse(storedProducts);
        setProducts(parsedProducts);
      } else {
        console.warn("No products data found in localStorage.");
      }
    }
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };

  // Close modal
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };

  // Handle form submission (Add button)
  const handleClose = () => {
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleDateChange = (date) => {
    setNextFollowup(date);
  };

  const handleDelete = (enquiryID) => {
    // Your delete functionality here
    console.log(`Delete enquiry with ID: ${enquiryID}`);
  };

  const handleEdit = (enquiryID) => {
    // Your edit functionality here
    console.log(`Edit enquiry with ID: ${enquiryID}`);
  };
  const [createFileSave, setcreatefileSave] = useState(false);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function company() {
    let companyFile = {
      company_name: companyname,
      segment: segment,
      territory: AreaTerritory,
      company_location: "abc",
      category: type,
      type: user,
    };
    console.log("companyFile:", companyFile);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
    ]);
  };

  const cancelLastContact = () => {
    if (contacts.length > 1) {
      setContacts(contacts.slice(0, -1));
    }
  };

  async function enquiry(companyId, companyAddressId) {
    console.log("Start Date in Enquiry : ", StartDate);
    
    if (enquiryProducts.length === 0) {
      alert("Please fill the product table with at least one product.");
      return false; // Prevent submission if the table is empty
    }
  
    // Construct the enquiry payload
    let enquiryFile = {
      company_id: companyId,
      company_address_id: companyAddressId,
      customer_name: Name,
      userId: user_id,
      email: EmailID, // Populated from state
      contact: ContactNumber, // Populated from state
      source: Source,
      comp_name: compName,
      gst_pin: gstin,
      total: Total,
      notes: Notes,
      assigned_to: assigned,
      date: NextFollowupAction,
      chalanges: Chalanges,
      requirement_type: Requirement,
      stages: stages,
      win_possibilities: winPossibilities,
      competitor: Competitor,
      priority: Priority,
      status: active,
      products: enquiryProducts,
      next_follow_date: StartDate,
      enquiry_title: enquiryTitle,
      user_quotation: userQuotation,
      company_contact_id: contacts[selectedContactIndex]?.company_contact_id || "",
      contact_details: {
        name: contacts[selectedContactIndex]?.name || "", // Add contact name
        designation: contacts[selectedContactIndex]?.designation || "", // Add designation
        email: contacts[selectedContactIndex]?.email || "", // Add email
        phone: contacts[selectedContactIndex]?.phone || "", // Add phone
      },
    };
  
    // Check for missing required fields
    const requiredFields = [
      "company_id",
      "company_address_id",
      "customer_name",
      "userId",
      "email",
      "contact",
      "total",
      "notes",
      "assigned_to",
      "date",
      "chalanges",
      "requirement_type",
      "stages",
      "win_possibilities",
      "competitor",
      "priority",
      "status",
      "products",
    ];
  
    // Validate contact details
    const contactRequiredFields = ["name", "email", "phone"];
    const missingContactFields = contactRequiredFields.filter(
      (field) => !enquiryFile.contact_details[field] || enquiryFile.contact_details[field] === ""
    );
  
    let missingFields = requiredFields.filter(
      (field) => !enquiryFile[field] || enquiryFile[field] === ""
    );
  
    if (missingContactFields.length > 0) {
      missingFields = missingFields.concat(
        missingContactFields.map((field) => `contact_details.${field}`)
      );
    }
  
    if (missingFields.length > 0) {
      alert(`Please fill all the required fields: ${missingFields.join(", ")}`);
      return false; // Validation failed
    }
  
    try {
      const response = await axiosConfig.post(`/enquiries`, enquiryFile);
  
      if (response.data.enquiry_id) {
        let data_enquiry_id = response.data.enquiry_id;
        localStorage.setItem("enquiryId", data_enquiry_id);
        console.log("Enquiry successfully posted:", response.data);
        return true;
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(error.response.data); // Show the error message from backend
      } else {
        console.error("Error posting enquiry:", error);
        alert("An error occurred while submitting the enquiry. Please try again.");
      }
      return false;
    }
  }
  
  
  let enquiryId = localStorage.getItem("enquiryId");

  useEffect(() => {
    // Check if the action is 'edit' in localStorage
    const action = localStorage.getItem("action");
    if (action === "edit") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    const storedCompanies = localStorage.getItem("companies");
    console.log('comopanies',storedCompanies);
    
    if (storedCompanies) {
      const parsedCompanies = JSON.parse(storedCompanies);
      setCompanies(parsedCompanies);
    } else {
      console.warn("No company data found in localStorage.");
    }
  }, []);

  const handleProductEdit = (serialNo) => {
    // Locate the product using the serial number (index in the array)
    const productToEdit = enquiryProducts[serialNo - 1];
  
    // Update the search term separately
    setProductSearchTerm(productToEdit.product_name);
  
    // Populate the form with the product details
    setFormData({
      productName: productToEdit.product_name, // Ensure this field is included
      productId: productToEdit.product_id,
      modelNo: productToEdit.model_no,
      qty: productToEdit.quantity,
      orderValue: productToEdit.order_value,
      description: productToEdit.description,
    });
  
    // Save the index for updating
    setSelectedProductIndex(serialNo - 1); // Store the index for the product being edited
    setIsEditing(true);
    setIsModalOpen(true);
  };
  
  const handleProductDelete = (serialNo) => {
    // Filter out the product by serial number (index)
    const updatedProducts = enquiryProducts.filter(
      (_, index) => index !== serialNo - 1
    );
  
    console.log('Updated Products:', updatedProducts); // Check products array
  
    // Update the enquiryProducts state first
    setEnquiryProducts(updatedProducts);
  };
  
// Recalculate total whenever `enquiryProducts` changes
useEffect(() => {
  const totalValue = enquiryProducts.reduce((sum, product) => sum + Number(product.order_value || 0), 0);
  
  console.log('Total Value:', totalValue); // Debugging
  setTotal(totalValue);
}, [enquiryProducts]); // Runs whenever `enquiryProducts` updates


  const saveEnquiry = async () => {
    setSubmitting(true);
    if (isEditMode) {
      console.log("Update enquiry");

      try {
      
        console.log("Start Date in Edit save : ", StartDate);
        const selectedContact = contacts[selectedContactIndex];
        // Create the body for the PUT request
        const requestBody = {
          customer_name: Name,
          email: EmailID,
          contact: ContactNumber,
          product_enquired: Productenquired,
          status: active,
          total: Total, // Add the Total field
          notes: Notes, // Add the Notes field
          assigned_to: assigned, // Add the Assigned field
          date: NextFollowupAction, // Add the NextFollowupAction field
          chalanges: Chalanges,
          requirement_type: Requirement,
          company_contact_id: selectedContact.company_contact_id,
          stages: stages,
          win_possibilities: winPossibilities,
          competitor: Competitor,
          priority: Priority,
          gst_pin: gstin,
          next_follow_date: StartDate,
          products: enquiryProducts,
          enquiry_title:enquiryTitle,
          user_quotation:userQuotation
        };

        console.log("Request Body:", requestBody);

        // Construct the URL without query parameters
        const url = `/enquiries/${enquiryID}`;

        // Perform the PUT request using axiosConfig
        const response = await axiosConfig.put(url, requestBody);

        // Handle the successful update
        console.log("Update response:", response.data);
       // Delay hiding the loader by 2 seconds before returning true
      setTimeout(() => {
        setSubmitting(false);
        setcreatefileSave(true); // Show dialog after delay
      }, 1000); 
        return true;
        // alert("Enquiry updated successfully");
      } catch (error) {
        // Handle any errors during the update
        console.error("Error updating enquiry:", error);
 
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
        alert("contact already exists in another enquiry");
        setTimeout(() => {
          setSubmitting(false);
        }, 1000); // Ensure the loader stays for 2 seconds even on error
  
        return false;
      }
    } else {
      console.log("Create enquiry");

      // Handle add logic here (if this is for creating an enquiry)
      // You may call other functions for creating an enquiry if needed
      await company(); 
      setTimeout(() => {
        setSubmitting(false);
        setcreatefileSave(true); // Show dialog after delay
      }, 2000);
    
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const getCompanyDetail = localStorage.getItem("companyDetails");
  // console.log("rajjjjj ", getCompanyDetail);
  const parsedCompanyDetail = JSON.parse(getCompanyDetail);
  // console.log("parsedCompanyDetail::", JSON.parse(getCompanyDetail));
  useEffect(() => {
    if (!isModalOpen) {
      setFormData({
        productName: "",
        productId: "",
        modelNo: "",
        qty: 0,
        orderValue: 0,
        description: "",
      });
    }
  }, [isModalOpen]);
  
  const handleAddProduct = () => {
    const updatedProduct = {
      product_name: formData.productName,
      product_id: formData.productId,
      model_no: formData.modelNo,
      quantity: formData.qty,
      order_value: formData.orderValue,
      description: formData.description,
    };

    let updatedProducts;

    if (isEditing) {
      // Update the product at the specific index
      updatedProducts = enquiryProducts.map((product, index) =>
        index === selectedProductIndex ? updatedProduct : product
      );
    } else {
      // Add a new product to the array
      updatedProducts = [...enquiryProducts, updatedProduct];
    }

    // Update state
    setEnquiryProducts(updatedProducts);
    
  
  
  

    // Clear the form and reset state
    setFormData({
      productName: "",
      productId: "",
      modelNo: "",
      qty: 0,
      orderValue: 0,
      description: "",
    });
    setProductSearchTerm("");
    setIsEditing(false);
    setSelectedProductIndex(null);
    setIsModalOpen(false);
  };



  useEffect(() => {
    if (resetFields) {
      // Clear all fields when resetFields is true
      setStartDate(new Date());
      setSearchTerm("");
      setEnquiryProducts([]);
      setCompanyId("");
      setCompanyAddressId("");
      setcompanyname("");
      setsegment("");
      setType("");
      setAreaTerritory("");
      setCategory("");
      setUser("");
      setEmailID("");
      setContactNumber("");
      setTotal("");
      setNotes("");
      setAssigned("");
      setChalanges("");
      setRequirement("");
      setStages("");
      setWinPossibilities("");
      setCompetitor("");
      setPriority("");
      setActive("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setDistrict("");
      setCity("");
      setState("");
      setPincode("");
      setGstin("")
      setSource("");
      setEnquiryTitle("");
      setUserQuotation("");
      setContacts([]);
      setResetFields(false);  // Reset the flag after clearing the fields
    }
  }, [resetFields]); // This effect triggers only when resetFields changes
  
  useEffect(() => {
    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      if (!resetFields) { // Prevent fetch if resetting
        axiosConfig
        .get(`/enquiries/${enquiryID}`)
          .then((response) => {
            const data = response.data;
            console.log("Fetched Data:", data);

            if (action === "edit") {
              setIsEditMode(true);
              const nextFollowUpDate = data.next_follow_date;

              if (nextFollowUpDate) {
                const parsedDate = new Date(nextFollowUpDate);
                if (!isNaN(parsedDate)) {
                  setStartDate(parsedDate);
                  console.log("Set StartDate from API:", parsedDate);
                } else {
                  console.error("Invalid date format from API, using today's date.");
                  setStartDate(new Date()); // Fallback to today's date if invalid
                }
              } else {
                console.log("No NextFollowup found in API, using today's date.");
                setStartDate(new Date());
              }
            }

            setEnquiryProducts(data.products || []);
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setUser(data.masterCompany?.category || "");
            setEmailID(data.email || "");
            setContactNumber(data.contact || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");
            setEnquiryTitle(data.enquiry_title || "");
            setUserQuotation(data.user_quotation || "");

            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_line_1 || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setGstin(address.gst_pin);
              setSource(address.source || "");
            }

            // Set contacts correctly
            const contactList = data.masterCompany?.contacts || [];
            setContacts(contactList);
            setSelectedContactIndex(contactList.findIndex(contact => contact.company_contact_id === data.company_contact_id) || 0);
          })
          .catch((error) => {
            if (axiosConfig.isCancel(error)) {
              console.log("Request canceled");
            } else {
              console.error("Error fetching enquiry details:", error);
            }
          });
      }
    }
  }, [enquiryID, resetFields]);
 // This effect triggers when enquiryID or resetFields changes
  
  const handleReset = () => {
    setResetFields(true);  // Trigger reset
  };
  
  // Clear all enquiry inputs and cancel any ongoing API requests
// const clearAllEnquiryInputs = () => {
//   // Cancel ongoing API request if present
//   if (cancelTokenRef.current) {
//     cancelTokenRef.current.cancel(); // Cancel the API request
//   }

//   // Reset enquiry-related state variables
//   setStartDate(new Date());
//   setSearchTerm("");
//   setEnquiryProducts([]);
//   setCompanyId("");
//   setCompanyAddressId("");
//   setcompanyname("");
//   setsegment("");
//   setType("");
//   setAreaTerritory("");
//   setCategory("");
//   setUser("");
//   setEmailID("");
//   setContactNumber("");
//   setTotal("");
//   setNotes("");
//   setAssigned("");
//   setChalanges("");
//   setRequirement("");
//   setStages("");
//   setWinPossibilities("");
//   setCompetitor("");
//   setPriority("");
//   setActive("");
//   setDoorNo("");
//   setstreetname("");
//   setStreetLine("");
//   setDistrict("");
//   setCity("");
//   setState("");
//   setPincode("");
//   setSource("");
//   setContacts([]);
// };


  useEffect(() => {
    if (isEditMode) {
      updateDatesBasedOnPriority();
    }
  }, [Priority, isEditMode]); 
  
  const updateDatesBasedOnPriority = () => {
    let daysToAdd = 0;
  
    // Adjust days based on Priority
    if (Priority === "low") daysToAdd = 6; // Low = 6 additional days (total 7 days including today)
    else if (Priority === "Medium") daysToAdd = 4; // Medium = 4 additional days (total 5 days including today)
    else if (Priority === "high") daysToAdd = 2; // High = 1 additional day (total 2 days including today)
  
    if (daysToAdd >= 0) {
      const today = new Date();
      const newEndDate = new Date(today);
      newEndDate.setDate(today.getDate() + daysToAdd);
  
      // If in Edit Mode, don't reset StartDate to today
      if (!StartDate || !isEditMode) {
        setStartDate(today); // Set StartDate to today if it was not already set
      }
  
      setEndDate(newEndDate); // Set new EndDate based on Priority
      console.log("Updated End Date:", newEndDate);
    } else {
      setEndDate(null); // Clear end date for invalid Priority
    }
  };
  
  // Function to check if a date is in the valid range
  const isDateInRange = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight for consistent comparison
    const endDate = new Date(EndDate);
    endDate.setHours(23, 59, 59, 999); // Include the full day for the range
  
    return date >= today && date <= endDate;
  };
  const [productSearchTerm, setProductSearchTerm] = useState(''); // Product search term
  const [filteredProducts, setFilteredProducts] = useState([]); // Filtered products based on search term


 
  useEffect(() => {
    const storedProducts = localStorage.getItem('products');
    if (storedProducts) {
      const parsedProducts = JSON.parse(storedProducts);
      setProducts(parsedProducts);
    } else {
      console.warn('No products data found in localStorage.');
    }
  }, []);

  // Handle product search input change
  const handleProductSearchChange = (e) => {
    const value = e.target.value;
    setProductSearchTerm(value);

    // Filter products based on the search term (case-insensitive)
    const filtered = products.filter((product) =>
      product.product_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(filtered); // Update filtered products
  };

  // Handle selecting a product from the dropdown
  const handleProductSelect = (product) => {

    setFormData({
      ...formData,
      productName: product.product_name,
      productId: product.product_id,
      modelNo: product.model_no,
      qty: product.quantity,
      orderValue: product.order_value,
      description: product.description,
    });

    setProductSearchTerm(product.product_name); // Set the selected product name in the input
    setFilteredProducts([]); // Clear the filtered products list after selection
    setIsDropdownOpen(false); // Close the dropdown
  };

  const isFormValid = () => {
    return (
      formData.productName?.trim() !== "" &&  
      formData.modelNo?.trim() !== "" &&     
      Number(formData.qty) > 0 &&           
      Number(formData.orderValue) > 0 &&     
      formData.description?.trim().length > 0   
    );
  };

  return (
    <div>
      <Header />
      <form>
      <div className={styles["button-container"]}>
      <button
  className={styles["button-backArrow"]} // Apply styling to the button
  onClick={() => navigate('/EnquiryManagement')} // Navigate on click
>
  <div className={styles["circle-container"]}>
    <FaArrowLeft size={14} /> {/* Render the back arrow icon */}
  </div>
  <span className={styles["back-text"]}>Back</span> {/* Add text after the icon */}
</button>
</div>
        <h2 className={styles['basic-info-title']}>Basic Information</h2>

        <div className={styles['basic-info-container']}>
          {/* Row 1 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Company Name<span  className={styles['required']}>*</span>
            </label>
            <input
              type="text"
              value={companyname || searchTerm}
              onChange={handleSearchChange}
              placeholder="Search company name"
              className={styles['basic-info-input']}
            />
            {searchTerm === "" && (
              <BsSearch className={styles['basic-info-search-icon']} />
            )}{" "}
            {filteredCompanies.length > 0 && (
              <ul className={styles['basic-info-dropdown-list']} >
                {filteredCompanies.map((company) => (
                  <li
                    key={company.company_id}
                    onClick={() => handleCompanySelect(company)}
                    className={styles['basic-info-dropdown-item']}
                  >
                    {company.company_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div  className={styles['basic-info-row']}>
            <label  className={styles['basic-info-label']}>Category</label>
            <input
              type="text"
              value={user}
              
              readOnly={!isEditable}
              onChange={handleChangeUser}
              placeholder=""
              className={styles['basic-info-input']}
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Source</label>
            <input
              type="text"
              value={Source}
              readOnly={!isEditable}
              onChange={(e) => setSource(e.target.value)}
              placeholder=""
              className={styles['basic-info-input']}
            />
          </div>

          {/* Row 2 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>
              Door No<span className={styles['required']} >*</span>
            </label>
            <input
              type="text"
              value={DoorNo}
              readOnly={!isEditable}
              onChange={(e) => setDoorNo(e.target.value)}
              required
              className={styles['basic-info-input']}
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Street Line-1</label>
            <input
              type="text"
              value={streetname}
              readOnly={!isEditable}
              onChange={(e) => setstreetname(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Street Line-2</label>
            <input
              type="text"
              value={StreetLine}
              readOnly={!isEditable}
              onChange={(e) => setStreetLine(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>

          {/* Row 3 */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>City</label>
            <input
              type="text"
              value={City}
              readOnly={!isEditable}
              onChange={(e) => setCity(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>District</label>
            <input
              type="text"
              value={District}
              readOnly={!isEditable}
              onChange={(e) => setDistrict(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>State</label>
            <input
              type="text"
              value={state}
              readOnly={!isEditable}
              onChange={(e) => setState(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>

          {/* Row 4 - Pincode */}
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Pincode</label>
            <input
              type="text"
              value={pincode}
              readOnly={!isEditable}
              onChange={(e) => setPincode(e.target.value)}
               className={styles['basic-info-input']}
            />
       
        </div>
        <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>GSTIN</label>
            <input
              type="text"
              value={gstin}
              readOnly={!isEditable}
              onChange={(e) => setPincode(e.target.value)}
               className={styles['basic-info-input']}
            />
          </div>
        </div>
      
      </form>

      <form>
      <h2 className={styles['basic-info-title']}>Customer Contact Person Details</h2>

<div className={styles['basic-info-container']}>
  {/* Row 1: Name */}
  <div className={styles['basic-info-row']}>
    <label className={styles['basic-info-label']}>Name</label>
    {contacts.length > 1 ? (
      <select
        value={selectedContactIndex}
        onChange={(e) => {
          const index = parseInt(e.target.value, 10); // Parse the index as an integer
          setSelectedContactIndex(index);
          handleContactSelect(index);
        }}
        className={styles['basic-info-input']}
      >
        {contacts.map((contact, index) => (
          <option key={contact.comp_contact_id} value={index}>
            {contact.name}
          </option>
        ))}
      </select>
    ) : (
      <input
        type="text"
        value={contacts[0]?.name || ""}
        readOnly={!isEditable}
        className={styles['basic-info-input']}
      />
    )}
  </div>

  {/* Row 2: Designation */}
  <div className={styles['basic-info-row']}>
    <label className={styles['basic-info-label']}>Designation</label>
    <input
      type="text"
      value={contacts[selectedContactIndex]?.designation || ""}
      readOnly={!isEditable}
      className={styles['basic-info-input']}
    />
  </div>

  {/* Row 3: Email ID */}
  <div className={styles['basic-info-row']}>
    <label className={styles['basic-info-label']}>Email ID</label>
    <input
      type="text"
      value={contacts[selectedContactIndex]?.email || ""}
      readOnly={!isEditable}
      className={styles['basic-info-input']}
    />
  </div>

  {/* Row 4: Contact Number */}
  <div className={styles['basic-info-row']}>
    <label className={styles['basic-info-label']}>Contact Number</label>
    <input
      type="text"
      value={contacts[selectedContactIndex]?.phone || ""}
      readOnly={!isEditable}
      className={styles['basic-info-input']}
    />
  </div>
</div>

  {/* Optionally, you can add an "Add Another Contact" button here */}
</form>

      
      <div>
        <h2 className={styles['basic-info-title']}>Enquiry Classification</h2>
        <div className={styles['basic-info-container']} >
          {/* Row 1: 3 columns */}
          <div  className={styles['row-field']} >
            <label className={styles['basic-info-label']} htmlFor="requirementType">
              Requirement Type
            </label>
            <select
              id="requirementType"
              value={Requirement}
              onChange={(e) => setRequirement(e.target.value)}
              className={styles['basic-info-input']}
            >
              <option value="" disabled hidden>
                Select Requirement Type
              </option>
              <option value="New">New</option>
              <option value="NCrossew">Cross-selling</option>
              <option value="UpSel">Up-selling</option>
              <option value="Exact">Exact Sale</option>
            </select>
          </div>

          <div  className={styles['row-field']}>
            <label className={styles['basic-info-label']} htmlFor="stages">
              Stages
            </label>
            <select
              id="stages"
              value={stages}
              onChange={(e) => setStages(e.target.value)}
              className={styles['basic-info-input']}
            >
              <option value="" disabled hidden>
                Select Stages
              </option>
              <option value="scope">Scope</option>
              <option value="design">Design</option>
              <option value="Procure">Procurement</option>
              <option value="Implement">Implementation</option>
              <option value="break">Breakdown</option>
              <option value="Spare">Spare</option>
            </select>
          </div>

          <div  className={styles['row-field']}>
            <label className={styles['basic-info-label']} htmlFor="priority">
              Priority
            </label>
            <select
              id="priority"
              value={Priority}
              onChange={(e) => setPriority(e.target.value)}
              className={styles['basic-info-input']}
            >
              <option value="" disabled hidden>
                Select Priority
              </option>
              <option value="low">Low</option>
              <option value="Medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>

          {/* Row 2: 3 columns */}
          <div  className={styles['row-field']}>
            <label className={styles['basic-info-label']} htmlFor="competitor">
              Competitor
            </label>
            <input
              type="text"
              id="competitor"
              value={Competitor}
              onChange={(e) => setCompetitor(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>

          <div  className={styles['row-field']}>
            <label className={styles['basic-info-label']} htmlFor="winPossibilities">
              Win Possibilities
            </label>
            <select
              id="winPossibilities"
              value={winPossibilities}
              onChange={(e) => setWinPossibilities(e.target.value)}
              className={styles['basic-info-input']}
            >
              <option value="" disabled hidden>
                Select Win Possibilities
              </option>
              <option value="50">50%</option>
              <option value="60">60%</option>
              <option value="70">70%</option>
              <option value="80">80%</option>
              <option value="90">90%</option>
              <option value="100">100%</option>
            </select>
          </div>

          <div className={styles['row-field']}>
            <label className={styles['basic-info-label']} htmlFor="chalanges">
              Chalanges
            </label>
            <input
              type="text"
              id="chalanges"
              value={Chalanges}
              onChange={(e) => setChalanges(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>
        </div>
      </div>

      <form>
        <h2 className={styles['basic-info-title']}>Product Details</h2>
        <div className={styles['product-container']}>
          <div className={styles['table-container']} >
            <table className={styles['product-table']}>
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Product Name</th>
                  <th>Model No</th>
                  <th>Qty</th>
                  <th>Order Value</th>
                  <th>Description</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {enquiryProducts.map((product, index) => (
                  <tr key={product.enquiry_product_id}>
                    <td>{index + 1}</td>
                    <td>{product.product_name}</td>
                    <td>{product.model_no}</td>
                    <td>{product.quantity}</td>
                    <td>{product.order_value}</td>
                    <td>{product.description}</td>
                    <td>
                      <BsTrash3Fill
                      className={styles['trash-icon']}
                        onClick={() => handleProductDelete(index + 1)}
                      />
                      <BsPencilFill
                        className={styles['edit-icon']}
                        onClick={() => handleProductEdit(index + 1)} // Pass serial number
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles['total-container']}>
  <label className={styles['basic-info-label']}>Total</label>
  <input
    type="text"
    value={Total}
    onChange={(e) => setTotal(e.target.value)} // Optional: If you want users to edit Total
    className={styles['basic-info-input']}
    readOnly
  />
</div>
          <button
            type="button"
            onClick={openModalWithDelay}
            className={styles['add-product-button']}
            
          >
            <BsPlusCircleFill className={styles["button-icon"]} />
            Add Product
          </button>

          {isModalOpen && (
            <div className={styles['modal-overlay']} >
              <div  className={styles['modal-container']} >
                <div className={styles['modal-header']} >
                  <h2 className={styles['modal-titles']}>Add Product</h2>
                  <div className={styles['close-modal']}  onClick={handleClose}>
                    ×
                  </div>
                </div>
                <form>
                <div className={styles["form-group"]}>
  <label className={styles["form-label"]}>Product</label>
  <div className={styles["input-container"]}>
    <input
      type="text"
      value={productSearchTerm || formData.productName || ""}
      onChange={handleProductSearchChange}
      placeholder="Search product"
      className={styles["proinput-field"]}
      onClick={handleDropdownClick} // Open dropdown when input is clicked
    />
    {productSearchTerm === "" && (
      <BsSearch className={styles["search-icon"]} />
    )}
  </div>
  {/* Dropdown for Filtered Products */}
  {isDropdownOpen && filteredProducts.length > 0 && (
    <ul className={styles["probasic-info-dropdown-list"]}>
      {filteredProducts.map((product) => (
        <li
          key={product.product_id}
          onClick={() => handleProductSelect(product)}
          className={styles["basic-info-dropdown-item"]}
        >
          {product.product_name}
        </li>
      ))}
    </ul>
  )}
</div>


                  <div className={styles['form-group']}>
                    <label className={styles['form-label']}>Model No</label>
                    <input
                      type="text"
                      name="modelNo"
                      value={formData.modelNo}
                      onChange={handleInputChange}
                      className={styles['input-field']}
                    />
                  </div>

                  <div className={styles['form-group']}>
  <label className={styles['form-label']}>Quantity</label>
  <input
    type="number"
    name="qty"
    value={formData.qty}
    onChange={(e) => handleInputChange(e, 'qty')}
    className={styles['input-field']}
    min="0" // Optional: Prevents negative numbers in some browsers
  />
</div>

<div className={styles['form-group']}>
  <label className={styles['form-label']}>Order Value</label>
  <input
    type="number"
    name="orderValue"
    value={formData.orderValue}
    onChange={(e) => handleInputChange(e, 'orderValue')}
    className={styles['input-field']}
    min="0" // Optional: Prevents negative numbers in some browsers
  />
</div>


                  <div className={styles['form-group']}>
                    <label className={styles['form-label']}>Description</label>
                    <textarea
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      className={styles['textarea-field']}
                    />
                  </div>

                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={(e) => {
                      e.preventDefault();  
                      handleAddProduct();  
                      }}
                      className={styles['submit-button']}
                      disabled={!isFormValid()}  // Disable button if form is incomplete
                      >
                      {isEditing ? "Update" : "Add"}
                    </button>
                  </div>

                </form>
              </div>
            </div>
          
          )}
        </div>
      </form>

      <form>
        <h2 className={styles['basic-info-title']}>Enquiry Details</h2>
        <div className={styles['basic-info-container']}>
          {/* Row 1: 3 columns */}
          <div  className={styles['row-field']} >
            <label htmlFor="name1"  className={styles['basic-info-label']} >
              Date
            </label>
            <input
              type="text"
              value={NextFollowupAction}
              onClick={() => {
                const today = new Date();
                const formattedDate = today.toISOString().split("T")[0];
                setNextFollowupAction(formattedDate);
              }}
              readOnly
              className={styles['basic-info-input']}
            />
          </div>

          <div className={styles['row-field']}>
      <label htmlFor="name2" className={styles['basic-info-label']}>
        Assigned To
      </label>
      <input
        type="text"
        id="name4"
        value={assigned} // The input will show the assigned user name
        onClick={() => setDropdownOpen(!dropdownOpen)}
        readOnly={assignedOptions.length > 1} // Only allow clicking when there are multiple options
        placeholder="Select an assignee"
        className={styles['basic-info-input']}
      />
      {dropdownOpen && assignedOptions.length > 1 && (
        <div className={styles['dropdown-list2']}>
          {assignedOptions.map((option) => (
            <div
              key={option.userId}
              onClick={() => handleSelect(option)}
              className={styles['dropdown-item2']}
            >
              {option.userName}
            </div>
          ))}
        </div>
      )}
    </div>
    
  {/* Next Follow-Up Date Picker */}
  <div className="styles['row-field']">
        <label htmlFor="next-followup" className={styles['basic-info-label']}>
          Next Follow-Up
        </label>
        <DatePicker
    selected={StartDate && !isNaN(StartDate) ? StartDate : null} // Use StartDate if valid, else null
    onChange={(date) => setStartDate(date)} // Update StartDate only on selection
    dateFormat="yyyy-MM-dd"
    placeholderText="Select a date"
    id="next-followup"
    className={styles['basic-info-input']}
    filterDate={isDateInRange} // Filter dates based on valid range
    minDate={new Date()} // Disable past dates
    onFocus={() => {
      if (!isEditMode) {
        updateDatesBasedOnPriority(); // Update dates only if not in edit mode
      }
    }}
  />
  
      </div>


    

          {/* New Note Input Field */}
          <div  className={styles['row-field']}>
            <label htmlFor="note"  className={styles['basic-info-label']}>
              Note
            </label>
            <textarea
              id="note"
              value={Notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter your note"
              className={styles['enquiry-area']}
              rows="4"
            />
          </div>
          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>Enquiry Title</label>
            <input
              type="text"
              value={enquiryTitle || ""} 
              onChange={(e) => setEnquiryTitle(e.target.value)}
              className={styles['basic-info-input']}
            />
          </div>

          <div className={styles['basic-info-row']}>
            <label className={styles['basic-info-label']}>User Quotation Id</label>
            <input
              type="text"
              value={userQuotation || ""}
              onChange={(e) => setUserQuotation(e.target.value)}
              className={styles['qbasic-info-input']}
          
            />
          </div>
        </div>

        
        
      </form>
      
      <div className={styles["enquiry-buttons"]}>
  {isSubmitting && (
    <div className={styles["spinner-container"]}>
      <div className={styles["loading-text"]}>Loading...</div>
      <div className={styles["spinner-custom"]} role="status"></div>
    </div>
  )}

  <Button
    variant="primary"
    className={styles["enquiry-primary"]}
    onClick={async () => {
      setSubmitting(true); // Set the loader to true first to show the spinner

      let isSuccess = false;
      try {
        if (isEditMode) {
          isSuccess = await saveEnquiry(); // Call update logic for edit mode
        } else {
          isSuccess = await enquiry(companyId, companyAddressId); // Call create logic for new enquiry
        }

        if (isSuccess) {
          setTimeout(() => {
            setcreatefileSave(true); // Show dialog only if the operation is successful
          }, 500); // Delay showing the dialog slightly
        }
      } catch (error) {
        console.error("Error during enquiry process:", error);
        alert("An error occurred. Please try again.");
      } finally {
        setTimeout(() => {
          setSubmitting(false);
        }, 1000); // Ensure the spinner stays for 2 seconds
      }
    }}
    disabled={isSubmitting} // Disable button while submitting
  >
    {!isSubmitting ? (
      <>
        <FaDownload className={styles["save-icon"]} />
        {isEditMode ? "Update" : "Submit"}
      </>
    ) : null} {/* Do not show text when submitting */}
  </Button>
</div>
<Dialog
  open={createFileSave}
  onClose={() => setcreatefileSave(false)}
  PaperProps={{
    className: styles['enquiry-dialog-paper'],
  }}
>
  <DialogContent
    dividers
    className={styles['enquiry-dialog-content']}
  >
    {/* Success Icon */}
    <img
      src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png" 
      alt="Success Icon"
      className={styles['enquiry-success-icon']}
    />
    <span
      className={styles['success-message']}
    >
      Enquiry ID: <strong>{localStorage.getItem("enquiryId")}</strong> Submitted Successfully!
    </span>
  </DialogContent>
  <DialogActions
    className="enquiry-dialog-actions"
  >
    <Button
      onClick={addNavigateButton}
      variant="contained"
      className={styles['enquiry-ok-button']}
      
    >
      Ok
    </Button>
  </DialogActions>
</Dialog>


<div className={styles['reset-but']}>
  <Button
    variant="primary"
    className={styles['enquiry-reset-btn']}
    onClick={() => {
      handleReset();  // Trigger the reset logic
    }}
  >
    <IoMdRefresh className={styles["reset-icon"]} />
    Reset
  </Button>
</div>

    </div>
  
  );
}
export default Enquiry;
