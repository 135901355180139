import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  BsInfoCircleFill,
  BsQuestionCircleFill,
  BsChatQuoteFill,
  BsFillCartCheckFill,
  BsPersonFill,
  BsFileEarmarkMedicalFill,
  BsBagFill,
} from "react-icons/bs";
import { FiCreditCard, FiSettings } from "react-icons/fi";
import { BiSolidBadgeDollar } from "react-icons/bi";
import { VscExport } from "react-icons/vsc";
import { Link } from "react-router-dom";
import avatar from "../Images/Profile.png";
import logo from "../Images/Erllogo.png";
import logout from "../Images/logoutimage.png";

import "bootstrap/dist/css/bootstrap.min.css";
import backgroundImage from "../Images/Sidebarbackgroundimage.png";
import { Outlet } from "react-router";
import React, { useState, useEffect } from "react";
import "../css/sidebarmenu.css";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

function SideMenu({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [error, setError] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // Track the selected menu item

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item); // Set the selected menu item
  };




  // const [loading, setLoading] = useState(false);

  // // Handle loading when navigating
  // const delayNavigation = (delay) => {
  //   setLoading(true);  // Show loading spinner
  //   setTimeout(() => {
  //     setLoading(false);  // Hide loading spinner after delay
  //   }, delay);
  // };

  // Get user info from localStorage
  let user_mail = localStorage.getItem("userEmail");
  let user_firstName = localStorage.getItem("user_firstName");
  let user_lastName = localStorage.getItem("user_lastName");

  // Session Timeout Handling (Example: 15 min)
  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      setSessionExpired(true); // Show popup when session expires
    }, 60 * 60 * 1000); // 15 minutes

    return () => clearTimeout(sessionTimeout); // Cleanup on unmount
  }, []);

  // Function to handle automatic logout
  const handleAutoLogout = () => {
    localStorage.clear(); // Clear session storage
    navigate("/login"); // Redirect to login
  };

 
  const handleLogout = (e) => {
    e.preventDefault(); // Prevent the default navigation
    handleMenuItemClick("Logout"); // Set the selected menu item to "Logout"
    handleLogoutClick(e); // Trigger the logout dialog
  };
 // Handle the logout button click to open the dialog
 const handleLogoutClick = (e) => {
  setOpenDialog(true); // Show logout confirmation dialog (No loading here)
};


// Handle user confirmation to logout
const handleConfirmLogout = () => {
  setLoading(true); // Start loading spinner before logout

  setTimeout(() => {
    localStorage.clear(); // Clear user data
    setLoading(false); // Stop loading spinner
    navigate('/login'); // Redirect to the login page
  }, 500); // Delay before redirection (adjust if needed)
};

// Display loading spinner
if (loading) {
  return (
    <div className="spinner-container">
      <div className="loading-text">Loading...</div>
      <div className="spinner-border spinner-custom" role="status"></div>
    </div>
  );
}

// If there's an error, display the error message
if (error) {
  return <div className="text-center text-danger mt-5">Error: {error}</div>;
}

  // Handle user canceling logout (close the dialog)
  const handleCancelLogout = () => {
    setOpenDialog(false); // Close the dialog and stay on the current page
  };

  // Handle Session Expiry Confirmation
  const handleSessionExpiry = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);

    // Trigger a custom resize event
    // window.dispatchEvent(new Event('sidebarToggle'));
  };

  // useEffect(() => {
  //   const handleSidebarToggle = () => {
  //     const sidebarWidth = collapsed ? '80px' : '260px';
  //     document.documentElement.style.setProperty('--sidebar-width', sidebarWidth);
  //   };

  //   // Listen to the custom toggle event
  //   window.addEventListener('sidebarToggle', handleSidebarToggle);

  //   // Cleanup listener
  //   return () => window.removeEventListener('sidebarToggle', handleSidebarToggle);
  // }, [collapsed]);

  return (
    <div className="sidebar-container">
      <div
        className="sidebar-wrapper"
        style={{ display: "flex", position: "relative" }}
      >
        {/* Sidebar */}
        <Sidebar
          collapsed={collapsed}
          className="sidebar-contain"
          style={{
            height: "100vh",
            width: collapsed ? "80px" : "260px", // Sidebar width changes based on collapsed state
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1,
            color: "white",
            overflow: "hidden",
            transition: "width 0.3s ease",
          }}
        >
          {/* Background Image */}
          <div className="sidebar-background"></div>

          {/* Overlay for Contrast */}
          <div className="sidebar-overlay "></div>

          {/* Content */}
          <div className="sidebar-content">
            <button onClick={toggleSidebar} className="sidebar-button">
              {collapsed ? "☰" : "☰"}
            </button>

            <Menu
              menuItemStyles={{
                button: {
                  "&:hover": {
                    backgroundColor: "#0B5394",
                    color: "#fff",
                    height: "50px",
                    borderRadius: "50px",
                    width: "100%",
                  },
                },
              }}
            >
              {/* Logo Section */}
              {!collapsed && (
                <Menu className="logo-section">
                  <img className="logo-img" src={logo} alt="Logo" />
                </Menu>
              )}

              {/* Avatar and Username */}
              {!collapsed && (
                <div className="user-info">
                  <div className="avatar-container">
                    <img className="avatar-img" alt="User" src={avatar} />
                  </div>

                  <div className="user-details">
                    <div className="user-name">
                      {user_firstName} {user_lastName}
                    </div>
                    <div className="user-role">Administrator</div>
                  </div>
                </div>
              )}

<MenuItem
  className={`menu-item ${selectedMenuItem === "CompanyManagement" ? "active" : ""}`}
  component={<Link to="/CompanyManagement" />}
  icon={<BsInfoCircleFill className="menu-icon" />}
  onClick={() => handleMenuItemClick("CompanyManagement")} // Set selected menu item
>
  {!collapsed && "Company Management"}
</MenuItem>

<MenuItem
  className={`menu-item ${selectedMenuItem === "ProductManagement" ? "active" : ""}`}
  component={<Link to="/ProductManagement" />}
  icon={<BsBagFill className="menu-icon" />}
  onClick={() => handleMenuItemClick("ProductManagement")} // Set selected menu item
>
  {!collapsed && "Product Management"}
</MenuItem>

<MenuItem
  className={`menu-item ${selectedMenuItem === "EnquiryManagement" ? "active" : ""}`}
  component={<Link to="/enquiryManagement" />}
  icon={<BsQuestionCircleFill className="menu-icon" />}
  onClick={() => handleMenuItemClick("EnquiryManagement")} // Set selected menu item
>
  {!collapsed && "Enquiry Management"}
</MenuItem>

<MenuItem
  className={`menu-item ${selectedMenuItem === "FollowupManagement" ? "active" : ""}`}
  component={<Link to="/followupManagement" />}
  icon={<VscExport className="menu-icon" />}
  onClick={() => handleMenuItemClick("FollowupManagement")} // Set selected menu item
>
  {!collapsed && "Follow Up"}
</MenuItem>

              {/* <MenuItem className="menu-item"
              component={<Link to="/quotationManagement" />}
              icon={<BsChatQuoteFill className="menu-icon" />}
            >
              {!collapsed && "Quotation Management"}
            </MenuItem> */}
              {/* <MenuItem className="menu-item"
              component={<Link to="/order" />}
              icon={<BsFillCartCheckFill className="menu-icon" />}
            >
              {!collapsed && "Order Management"}
            </MenuItem> */}
              {/* <MenuItem className="menu-item"
              component={<Link to="/pay" />}
              icon={<FiCreditCard className="menu-icon"/>}
            >
              {!collapsed && "Payment Status Tracking"}
            </MenuItem> */}
           <MenuItem
  className={`menu-item ${selectedMenuItem === "ClosureManagement" ? "active" : ""}`}
  component={<Link to="/closure" />}
  icon={<BiSolidBadgeDollar className="menu-icon" />}
  onClick={() => handleMenuItemClick("ClosureManagement")} // Set selected menu item
>
  {!collapsed && "Closure Management"}
</MenuItem>

              {/* <MenuItem className="menu-item"
              component={<Link to="/user" />}
              icon={<BsPersonFill className="menu-icon" />}
            >
              {!collapsed && "Manage User"}
            </MenuItem> */}
              {/* <MenuItem className="menu-item"
              component={<Link to="/dash" />}
              icon={<BsFileEarmarkMedicalFill className="menu-icon"/>}
            >
              {!collapsed && "Reports"}
            </MenuItem> */}
              {/* <MenuItem className="menu-item"
              component={<Link to="/board" />}
              icon={<FiSettings className="menu-icon" />}
            >
              {!collapsed && "Settings"}
            </MenuItem> */}
            <MenuItem
  className={`menu-item ${selectedMenuItem === "Logout" ? "active" : ""}`} // Highlight the "Logout" item when selected
  component={<Link to="/login" />} // Navigation link to login (this will be handled by the Link)
  icon={<VscExport className="menu-icon" />}
  onClick={handleLogout} // Trigger the logout and highlight the item
>
  {!collapsed && "Logout"}
</MenuItem>
            </Menu>
          </div>

          {/* Logout Confirmation Dialog */}
          <Dialog open={openDialog} onClose={handleCancelLogout}>
            <img className="logoutImage" src={logout} alt="Logout" />
            <DialogTitle className="dialog-title">Log out</DialogTitle>
            <DialogContent className="dialog-content">
              <p className="dialog-text">Oh no! You're Leaving....</p>
              <p className="dialog-are">Are you sure?</p>
            </DialogContent>

            <DialogActions className="dialog-actions">
              <Button onClick={handleCancelLogout} className="cancelButton">
                Cancel
              </Button>
              <div className="button-container">
                <Button onClick={handleConfirmLogout} className="logoutButton">
                  <span className="logoutText">Logout</span>
                  <span className="rightArrow">[&#8594;</span>{" "}
                  {/* Right arrow */}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog open={sessionExpired} onClose={handleSessionExpiry}>
          <DialogTitle className="dialog-title">Session Expired</DialogTitle>
          <DialogContent className="dialog-content">
            <p className="dialog-text">Your session has expired. Please log in again.</p>
          </DialogContent>
          <DialogActions className="dialog-actions">
            <Button onClick={handleSessionExpiry} className="logoutButton">OK</Button>
          </DialogActions>
        </Dialog>
        </Sidebar>
        {/*     
      <div
  className="main-content"
  style={{
    marginLeft: collapsed ? '80px' : '260px', // Adjust content space based on sidebar state
    transition: 'margin-left 0.3s ease', // Smooth transition for content
    width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 260px)', // Adjust width to fill available space
    padding: '20px', // Optional padding for content
  }}
>
  {children}
</div> */}
      </div>
    </div>
  );
}
export default SideMenu;
