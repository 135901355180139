import React, { useState, useEffect } from "react";
import "../../css/Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Spinner } from "react-bootstrap"; // Import Spinner
import company from "../../css/CompanyManagement.module.css";
import styles from "../../css/EnquiryManagement.module.css";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsChevronDown,
} from "react-icons/bs";
import { MdBusiness, MdPerson } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { FaIdBadge, FaTasks } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import axiosConfig from "../../services/axiosConfig";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Header from "../../home/Header";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function CompanyManagement() {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  const getCompaies = async () => {
    setLoading(true); // Set loading to true before API call
    setError(null); // Reset error state before the API call
    try {
      const response = await axiosConfig.get("/companies");
      const rawData = response.data;
      const processedData = rawData.map((item, index) => ({
        serialNumber: index + 1,
        companyId: item.company_id,
        Company_Name: item.company_name || "N/A",
        Industry_Type: item.category || "N/A",
        Company_Phone: item.contact_details?.[0]?.phone || "N/A",
        Contact_Name: item.contact_details?.[0]?.name || "N/A",
        Phone_Number: item.contact_details?.[0]?.phone || "N/A",
        Email_Address: item.contact_details?.[0]?.email || "N/A",
        Job_Title: item.contact_details?.[0]?.designation || "N/A",
      }));

      setTableData(processedData);
      localStorage.setItem(
        "companies",
        JSON.stringify(
          rawData.sort((a, b) => a.company_name.localeCompare(b.company_name))
        )
      );
    } catch (error) {
      setError("Error fetching data: " + error.message); // Set error state
      console.error("Error fetching data:", error);
    } finally {
      // Simulate a delay for the loading spinner
      setTimeout(() => {
        setLoading(false); 
       }, 500); 
    }
  };

  useEffect(() => {
   getCompaies();
  }, []);
 

  if (loading) {
    return (
      <div className={styles['spinner-container']}>
        <div className="loading-text">Loading...</div>
        <div className="spinner-border spinner-custom" role="status">
        
        </div>
      </div>
    );
  }
   // If there's an error, display the error message
  if (error) {
    return <div className="text-center text-danger mt-5">Error: {error}</div>;
  }

  const handleAddCompany = () => {
    navigate("/company", { state: { mode: "create" } });
  };

  const handleEditCompany = (id) => {
    navigate("/company", { state: { companyId: id, mode: "edit" } });
  };

  const columns = [
    {
      dataField: "serialNumber",
      text: "S.No",
      sort: true,
      headerClasses: styles["header-sn"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className={styles["icon-color"]} />}
          title="S.No"
          tooltipText="Serial Number"
        />
      ),
    },
    {
      dataField: "Company_Name",
      text: "Company Name",
      sort: true,
      headerClasses: styles["header-company"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness className={styles["icon-color"]} />}
          title="Company Name"
          tooltipText="Company Name"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "Industry_Type",
      text: "Industry Type",
      sort: true,
      headerClasses: styles["header-industry"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className={styles["icon-color"]} />}
          title="Industry"
          tooltipText="Industry Type"
        />
      ),
    },
    {
      dataField: "Contact_Name",
      text: "Contact Name",
      sort: true,
      headerClasses: styles["header-contact"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdPerson className={styles["icon-color"]} />}
          title="Contact"
          tooltipText="Contact Name"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "Job_Title",
      text: "Job Title",
      sort: true,
      headerClasses: styles["header-job"],
      classes: styles["cell-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge className={styles["icon-color"]} />}
          title="Job"
          tooltipText="Job Title"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "Email_Address",
      text: "Email Address",
      sort: true,
      headerClasses: styles["header-email"],
      classes: styles["cell-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsFillEnvelopeFill className={styles["icon-color"]} />}
          title="Email"
          tooltipText="Email Address"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    
    },

    {
      dataField: "Phone_Number",
      text: "Phone Number",
      sort: true,
      headerClasses: styles["header-mobile"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsTelephone className={styles["icon-color"]} />}
          title="Phone"
          tooltipText="Phone Number"
        />
      ),
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: styles["header-action"],
      classes: styles["cell-nowrap-center"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTasks className={styles["icon-color"]} />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => (
        <div className={styles["action-buttons"]}>
          <BsPencilFill
            className={styles["action-icon-edit"]}
            onClick={() => handleEditCompany(row.companyId)}
          />
          {/* <BsTrash3Fill
            className={styles["action-icon-delete"]}
            onClick={() => handleDeleteCompany(row.companyId)}
          /> */}
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    paginationClassName: "custom-pagination-container",
  });

  return (
    <div>
      <Header />
      <div className={company["company-box"]}>
        <div className={company["company-contain"]}>
          <Button
            variant="primary"
            className={company["add-company-button"]}
            onClick={handleAddCompany}
          >
            <BsPlusCircleFill className={company["add-company-button-icon"]} />
            Add New Company
          </Button>
        </div>
      </div>
      <div className={company["company-table"]}>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={tableData}
          columns={columns}
          pagination={pagination}
        />
      </div>
    </div>
  );
}

export default CompanyManagement;
