import React, { useState, useEffect } from "react";
import { Button, Alert, } from "react-bootstrap";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { BsPlusCircleFill } from "react-icons/bs";
import { FaDownload } from "react-icons/fa6";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate, useLocation } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import axiosConfig from "../../services/axiosConfig";
import "../../css/Header.css";
import styles from "../../css/Enquiry.module.css";
import Header from "../../home/Header";
import { FaArrowLeft,FaTrash, FaPlus,FaSpinner  } from 'react-icons/fa';




function Company() {
  const [companyName, setCompanyName] = useState("");
  const [category, setCategory] = useState("");
  const [source, setSource] = useState("");
  const [doorNo, setDoorNo] = useState("");
  const [streetLine1, setStreetLine1] = useState("");
  const [streetLine2, setStreetLine2] = useState("");
  const [area, setArea] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [pinCode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState("");
  const [gstError, setGstError] = useState("");
  const [contactErrors, setContactErrors] = useState({}); 


  const [contacts, setContacts] = useState([
    { name: "", designation: "", email: "", phone: "" },
  ]);
  const [contactError, setContactError] = useState("");
  const [gstin, setGstin] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
 
  const loadCompanyDetails = async () => {
    if (params.mode === "edit" && params.companyId) {
      try {
        const response = await axiosConfig.get(`/companies/${params.companyId}`);
        const companyDetails = response.data;
        const addressDetails = companyDetails.address_details;
        const contactDetails = companyDetails.contact_details;
  
        // Set company details
        setCompanyName(companyDetails.company_name);
        setCategory(companyDetails.category);
  
        // Set address details
        setSource(addressDetails.source);
        setDoorNo(addressDetails.door_no);
        setStreetLine1(addressDetails.street_line_1);
        setStreetLine2(addressDetails.street_line_2);
        setArea(addressDetails.area);
        setDistrict(addressDetails.district);
        setState(addressDetails.state);
        setCity(addressDetails.city);
        setPincode(addressDetails.postal_code);
        setGstin(addressDetails.gst_pin);
  
        // Set contact details
        setContacts(contactDetails);
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    }
  };
  
  useEffect(() => {
    if (params.mode === "edit" && params.companyId) {
      // Fetch company data to pre-populate the form
      const fetchCompanyData = async () => {
        try {
          const response = await axiosConfig.get(`/companies/${params.companyId}`);
          const companyData = response.data;
  
          // Set form fields including contacts
          setCompanyName(companyData.company_name);
          setCategory(companyData.category);
          setSource(companyData.address_details.source);
          setDoorNo(companyData.address_details.door_no);
          setStreetLine1(companyData.address_details.street_line_1);
          setStreetLine2(companyData.address_details.street_line_2);
          setArea(companyData.address_details.area);
          setDistrict(companyData.address_details.district);
          setState(companyData.address_details.state);
          setCity(companyData.address_details.city);
          setPincode(companyData.address_details.postal_code);
          setGstin(companyData.address_details.gst_in);
          setContacts(companyData.contact_details || []); // Load existing contacts
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      };
  
      fetchCompanyData();
    }
  }, [params.mode, params.companyId]); 

 
  const [emailError, setEmailError] = useState(""); // For email error
  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;

    setContacts(updatedContacts);
  };
  

  const handlePhoneChange = (index, value) => {
    const updatedContacts = [...contacts];
  
    // Only keep numeric characters
    const numericValue = value.replace(/\D/g, ''); // This removes all non-digit characters
  
    // Update the phone number with only numeric characters
    updatedContacts[index].phone = numericValue;
  
    // Update contacts state with the modified phone number
    setContacts(updatedContacts);
  
    // If the value is not numeric, set the error message for that specific contact
    if (value !== numericValue) {
      setContactErrors((prevErrors) => ({
        ...prevErrors, // Spread previous errors
        // [index]: "Only numeric values are allowed", // Set error message for this specific contact
      }));
    } else {
      // Remove the error for the specific contact if input is valid
      setContactErrors((prevErrors) => {
        const newErrors = { ...prevErrors }; // Ensure prevErrors is copied as an object
        delete newErrors[index]; // Remove error for the specific index
        return newErrors; // Return the updated object
      });
    }
  };
  
  
  const addContact = () => {
    // Add a new contact with a unique ID and empty values
    setContacts((prevContacts) => [
      ...prevContacts, // Keep existing contacts
      {
        designation: "",
        email: "",
        name: "",
        phone: "",
      },
    ]);
  };
  
  // Example for deleting a contact
  // const deleteContact = (index) => {
  //   setContacts(prevContacts => prevContacts.filter((_, i) => i !== index));
  // };
  // Sync contacts with backend in edit mode
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state
  const saveCompany = async () => {
    setIsSubmitting(true); // Show loader when submitting

    console.log("Contacts before validation:", contacts); // Check the contacts
    
    if (
      !companyName ||
      !category ||
      !source ||
      !doorNo ||
      !streetLine1 ||
      !area ||
      !district ||
      !state ||
      !city ||
      !pinCode ||
      !gstin ||
      contacts.some(contact => !contact.name || !contact.email || !contact.phone)
    ) {
      alert("Please fill in all the required fields.");
      setIsSubmitting(false); // Hide loader if validation fails
      return;
    }

    try {
      const updatedContacts = contacts.map(contact => {
        let updatedContact = {
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          designation: contact.designation,
        };
  
        // Only include company_contact_id if it exists (for editing)
        if (params.mode === "edit" && contact.company_contact_id) {
          updatedContact.company_contact_id = contact.company_contact_id;
        }
        return updatedContact;
      });

      const payload = {
        company_name: companyName,
        category: category,
        address_details: {
          source: source,
          door_no: doorNo,
          street_line_1: streetLine1,
          street_line_2: streetLine2,
          area: area,
          district: district,
          state: state,
          city: city,
          postal_code: pinCode,
          gst_pin: gstin,
        },
        contact_details: updatedContacts,
      };

      console.log("Payload to send:", payload);

      if (params.mode === "edit") {
        await axiosConfig.put(`/companies/${params.companyId}`, payload);
        alert("Company updated successfully!");
      } else {
        await axiosConfig.post("/companies", payload);
        alert("Company added successfully!");
      }

      loadCompanyDetails(); // Ensure to reload company details after submission
    } catch (error) {
      console.error("Error for saving payload:", error);
      alert("Data already exists");
    }

    setIsSubmitting(false); // Hide loader once the submission process is done
    setOpenDialog(true); // Open dialog on successful save
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/CompanyManagement");
  };

  useEffect(() => {
    if (!isReset) {
      loadCompanyDetails(); // Load details only when not in reset state
    }
  }, [isReset]);

  const resetForm = () => {
    setCompanyName("");
    setCategory("");
    setSource("");
    setDoorNo("");
    setStreetLine1("");
    setStreetLine2("");
    setArea("");
    setDistrict("");
    setState("");
    setPincode("");
    setCity("");
    setGstin("");
    setContacts([{ name: "", designation: "", email: "", phone: "" }]); // Reset contacts
  };

  const resetToDefault = () => {
    setCompanyName("");
    setCategory("");
    setSource("");
    setDoorNo("");
    setStreetLine1("");
    setStreetLine2("");
    setArea("");
    setDistrict("");
    setState("");
    setPincode("");
    setCity("");
    setGstin("");
    setContacts([{ name: "", designation: "", email: "", phone: "" }]);
    setIsReset(false); // Set `isReset` to false to allow the form to be re-rendered
  };

  // Trigger resetToDefault when isReset becomes true
  useEffect(() => {
    if (isReset) {
      resetToDefault();
    }
  }, [isReset]);
  return (
    <div>
      <Header />
      <form>
     
      <div className={styles["button-container"]}>
      <button
  className={styles["button-backArrow"]} // Apply styling to the button
  onClick={() => navigate('/CompanyManagement')} // Navigate on click
>
  <div className={styles["circle-container"]}>
    <FaArrowLeft size={14} /> {/* Render the back arrow icon */}
  </div>
  <span className={styles["back-text"]}>Back</span> {/* Add text after the icon */}
</button>


    </div>
        <h2 className={styles["basic-info-title"]}>
          {params.mode === "edit" ? "Edit Company" : "Add Company"}
        </h2>

        {/* Company Name */}
        <div className={styles["combasic-info-container"]}>
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>
              Company Name<span className={styles["required"]}>*</span>
            </label>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className={styles["basic-info-input"]}
              placeholder="Enter Company Name"
            />
          </div>
          {/* Category */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Category</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className={styles["basic-info-input"]}
            >
              <option value="" disabled>
                Select
              </option>{" "}
              {/* Placeholder option */}
              <option value="OEM">OEM</option>
              <option value="SPM">SPM</option>
              <option value="End Customer">End Customer</option>
              <option value="Distributor">Distributor</option>
            </select>
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Source</label>
            <select
              value={source}
              onChange={(e) => setSource(e.target.value)}
              className={styles["basic-info-input"]}
            >
              <option value="" disabled>
                Select
              </option>{" "}
              {/* Placeholder option */}
              <option value="Walk-in">Walk-in</option>
              <option value="Mail">Mail</option>
              <option value="Expo">Expo</option>
              <option value="Website">Website</option>
            </select>
          </div>

          {/* Row 2 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>
              Door No<span className={styles["required"]}>*</span>
            </label>
            <input
              type="text"
              value={doorNo}
              onChange={(e) => setDoorNo(e.target.value)}
              required
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Street Line-1</label>
            <input
              type="text"
              value={streetLine1}
              onChange={(e) => setStreetLine1(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Street Line-2</label>
            <input
              type="text"
              value={streetLine2}
              onChange={(e) => setStreetLine2(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          {/* Row 3 */}
          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>Area</label>
            <input
              type="text"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>District</label>
            <input
              type="text"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>State</label>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>

          <div className={styles["basic-info-row"]}>
            <label className={styles["basic-info-label"]}>City</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className={styles["basic-info-input"]}
            />
          </div>
{/* Row 4 - Pincode */}
<div className={styles["basic-info-row"]}>
  <label className={styles["basic-info-label"]}>Pincode</label>
  <input
    type="text"
    value={pinCode}
    onChange={(e) => {
      const newValue = e.target.value;

      // Validate that only numeric values are entered
      if (/^\d*$/.test(newValue)) {
        setPincode(newValue);  // Update pinCode if valid
        setError("");  // Clear error if the value is valid
      } else {
        setError("Only numeric values are allowed");  // Set error message if invalid
      }
    }}
    className={`${styles["basic-info-input"]} ${error ? styles["input-error"] : ""}`} // Apply red border when error exists
  />
  {error && <p className={styles["pinerror-message"]}>{error}</p>} {/* Show error message if error exists */}
</div>


<div className={styles["basic-info-row"]}>
      <label className={styles["basic-info-label"]}>GSTIN</label>
      <input
        type="text"
        value={gstin}
        onChange={(e) => {
          const newValue = e.target.value;
          // Allow only numeric values
          if (/^\d*$/.test(newValue)) {
            setGstin(newValue);  // Set GSTIN value
            setGstError("");  // Clear error message when valid input is entered
          } else {
            setGstError("Only numeric values are allowed");  // Show error message for invalid input
          }
        }}
        className={`${styles["basic-info-input"]} ${gstError ? styles["input-error"] : ""}`} // Apply red border when error exists
      />
      {gstError && <p className={styles["gsterror-message"]}>{gstError}</p>}
    </div>
        </div>
      </form>
      <div>
  {contacts.map((contact, index) => (
   <form key={contact.id || index}>
       <div className={styles["header-container"]}>
      <h2 className={styles["company-info-title"]}>
        Customer Contact Person Details {index + 1}
      </h2>
      <div className={styles["icon-buttons"]}>
    {/* Delete Contact Icon */}
    {/* {index > 0 && (
      <button
        type="button"
        className={styles["icon-button"]}
        onClick={() => deleteContact(index)}
      >
       <span className={`${styles["icon-container"]} ${styles["tooltip-container"]}`}></span>
        <FaTrash size={18} title="Delete Contact" />
        <span className={styles["tooltip"]}></span>
      </button>
    )} */}
    {/* Add Contact Icon */}
    {index === contacts.length - 1 && (
      <button
        type="button"
        className={styles["icon-button"]}
        onClick={addContact} >
      
       <span className={`${styles["icon-container"]} ${styles["tooltip-container"]}`}></span>
        <FaPlus size={18} title="Add Contact" />
        
        <span className={styles["tooltip"]}></span>
      </button>
    )} 
     </div>
</div>

      <div className={styles["company-container"]}>
        {/* Name */}
        <div className={styles["basic-info-row"]}>
          <label className={styles["basic-info-label"]}>Name</label>
          <input
            type="text"
            value={contact.name}
            onChange={(e) =>
              handleContactChange(index, "name", e.target.value)
            }
            className={styles["basic-info-input"]}
          />
        </div>

        {/* Designation */}
        <div className={styles["basic-info-row"]}>
          <label className={styles["basic-info-label"]}>Designation</label>
          <input
            type="text"
            value={contact.designation}
            onChange={(e) =>
              handleContactChange(index, "designation", e.target.value)
            }
            className={styles["basic-info-input"]}
          />
        </div>

        {/* Email */}
        <div className={styles["basic-info-row"]}>
          <label className={styles["basic-info-label"]}>Email ID</label>
          <input
            type="email"
            value={contact.email}
            onChange={(e) =>
              handleContactChange(index, "email", e.target.value)
            }
            className={styles["basic-info-input"]}
          />
        </div>


        <div className={styles["basic-info-row"]} key={index}>
    <label className={styles["basic-info-label"]}>Contact Number</label>
    <input
      type="text"
      value={contact.phone}
      onChange={(e) => handlePhoneChange(index, e.target.value)} // Pass the index to update that contact
      className={`${styles["basic-info-input"]} ${contactErrors[index] ? styles["input-error"] : ""}`} // Apply error class only for the current contact
    />
    {contactErrors[index] && (
      <p className={styles["phoneerror-message"]}>{contactErrors[index]}</p> // Show error message for the current contact
    )}
  </div>
   
     
        {/* {params.mode === "create" && index === contacts.length - 1 && (
          <div className={styles["right-align-button"]}>
            <button
              type="button"
              className={styles["add-company-button"]}
              onClick={addContact}
            >
              <BsPlusCircleFill className={styles["button-icon"]} />
              Add Another Contact
            </button>
          </div>
        )} */}
      </div>
    </form>
  ))}



   
  
<div className={styles["enquiry-buttons"]}>
  {isSubmitting && (
    <div className="spinner-container">
    <div className="loading-text">Loading...</div>
    <div className="spinner-border spinner-custom" role="status"></div>
  </div>
  )}

  <Button
    variant="primary"
    className={styles["enquiry-primary"]}
    onClick={saveCompany}
    disabled={isSubmitting} // Disable button while submitting
  >
    {!isSubmitting && (
      <>
        <FaDownload className={styles["save-icon"]} />
        {params.mode === "edit" ? "Update" : "Submit"}
      </>
    )}
  </Button>
</div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            className: styles["enquiry-dialog-paper"],
          }}
        >
          <DialogContent dividers className={styles["enquiry-dialog-content"]}>
            {/* Success Icon */}
            <img
              src="https://cdn-icons-png.flaticon.com/512/5290/5290058.png"
              alt="Success Icon"
              className={styles["enquiry-success-icon"]}
            />
            <span className={styles["success-message"]}>
              {params.mode === "edit"
                ? "Company has been successfully updated."
                : "Company has been successfully created."}
            </span>
          </DialogContent>
          <DialogActions className="enquiry-dialog-actions">
            <Button
              onClick={handleCloseDialog}
              variant="contained"
              className={styles["enquiry-ok-button"]}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <div className={styles["reset-but"]}>
        <Button
          variant="primary"
          className={styles["enquiry-reset-btn"]}
          onClick={resetForm} // Call resetForm on click
        >
          <IoMdRefresh className={styles["reset-icon"]} />
          Reset
        </Button>
      </div>
    
      </div>
    </div>
  );
}

export default Company;
