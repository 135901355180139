import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import styles from "../../css/EnquiryManagement.module.css";
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsCurrencyRupee,
  BsChevronDown,
  BsFilter,
} from "react-icons/bs";
import { MdBusiness, MdPerson ,MdAssignment } from "react-icons/md";
import { MdInfo } from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsFileText } from "react-icons/bs";
import { BsPerson } from "react-icons/bs"; // Import a relevant icon
import { FaIdBadge, FaCalendarAlt, FaUser,FaTasks } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import axiosConfig from "../../services/axiosConfig";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


import Header from "../../home/Header";
import { CSVLink } from "react-csv";


const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function EnquiryManagement() {
  const [enquires, setEnquires] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state
  const [filterEnquiry, setFilterEnquiry] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(["Open"]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [noDataMessage, setNoDataMessage] = useState("");
  const role = localStorage.getItem("role");

  const userId=localStorage.getItem("loggedInUserId")
  const compName=localStorage.getItem("compName")
  const [selectedEnquiryID, setSelectedEnquiryID] = useState(null);


  // const textRefs = useRef([]); // Declare textRefs to store references to each cell
  // const [isOverflowing, setIsOverflowing] = useState([]); // State to track overflow status

  // useEffect(() => {
  //   textRefs.current.forEach((ref, index) => {
  //     if (ref) {
  //       const lineHeight = parseFloat(getComputedStyle(ref).lineHeight); // Get line height
  //       const maxLines = 2; 
  //       const maxHeight = lineHeight * maxLines; // Calculate max height for 2 lines
  
  //       const isOverflow = ref.scrollHeight > maxHeight; // Check if content exceeds 2 lines
  
  //       if (isOverflow) {
  //         ref.classList.add('overflow'); // Add class if overflow
  //       } else {
  //         ref.classList.remove('overflow'); // Remove class if no overflow
  //       }
  
  //       console.log(`Overflow at index ${index}: ${isOverflow}`);
  //     }
  //   });
  // }, [enquires]); // Run when 'enquires' change
  
  
  useEffect(() => {
    fetchEnquiries('?status=Open');
  }, []);
  const fetchEnquiries = async (queryParams = "") => {
    setLoading(true);
    setError(null);
  
    try {
      const response = await axiosConfig.get(`/enquiries${queryParams}`);
  
      console.log("API Response:", response.data); // Debugging API response
  
      const formattedData = response.data
        .filter((item) => {
          console.log(
            "Assigned to:", item.assigned_to, 
            "Logged-in User ID:", userId,
            "Role:", role, 
            "Comp Name:", compName
          ); // Debugging
  
          if (role === "SalesManager") {
           
            return item.comp_name?.toLowerCase() === compName?.toLowerCase();
          } else if (role === "Admin") {
           
            return true;
          } else if(role ==="SalesEngineer"){
           
            return item.user_id == userId;
          }
        })
        .map((item, index) => {
          const product = item.products && item.products.length > 0 ? item.products[0] : {};
  
          return {
            id: index + 1,
            enquiryID: `${item.enquiry_id || index + 1}`,
            Company_Name: item.masterCompany?.company_name || "Unknown Company",
            Name: item.masterCompanyContact?.name || "N/A",
            Mobile_No: item.masterCompanyContact?.phone || "N/A",
            Email_Id: item.masterCompanyContact?.email || "N/A",
            ExpectedPrice: item.total ? `₹${Number(item.total).toLocaleString()}` : "N/A",
            Created_Date: item.date || "N/A",
            status: item.status || "N/A",
            Assigned_To:item.assigned_to || "N/A",
            Notes: item.notes || "N/A",
            Product_Name: product.product_name || "N/A",
            Model_No: product.model_no || "N/A",
            Quantity: product.quantity || "N/A",
            Order_Value: product.order_value || "N/A",
            Description: product.description || "N/A",
            Enquiry_Title: item.enquiry_title || "N/A",
            User_Quotation_Id: item.user_quotation ||"N/A"
          };
        });
  
      // Handle no data
      if (formattedData.length > 0) {
        setEnquires(formattedData);
        setNoDataMessage(""); // Clear no data message
      } else {
        setEnquires([]);
        setNoDataMessage("No data available for the assigned user.");
      }
  
      setFilterEnquiry([]);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setEnquires([]);
        setFilterEnquiry([]);
      } else {
        console.error("Error fetching data:", error);
        setError("Unexpected error occurred while fetching data.");
      }
    } finally {
      // Simulate a delay for the loading spinner
      setTimeout(() => {
        setLoading(false); 
      }, 500); // Set loading to false after API call is finished
    
    }
  };
  

  
  
  
  
  // Display loading spinner with a delay
  if (loading) {
    return (
      <div className={styles["spinner-container"]}>
        <div className="loading-text">Loading...</div>
        <div className="spinner-border spinner-custom" role="status"></div>
      </div>
    );
  }

  // If there's an error, display the error message
  if (error) {
    return <div className="text-center text-danger mt-5">Error: {error}</div>;
  }

  const addNavigateButton = () => {
    localStorage.setItem("action", "add");
    navigate("/enquiry");
  };

  const handleStatusChange = (e) => {
    const { value } = e.target;
    setSelectedStatus((prev) =>
      prev.includes(value)
        ? prev.filter((status) => status !== value)
        : [...prev, value]
    );
  };

  const handleApplyFilters = () => {
    let queryParams = [];
    
    if (fromDate && toDate) {
      queryParams.push(`fromDate=${fromDate}&toDate=${toDate}`);
    }
    
    if (selectedStatus.length > 0) {
      selectedStatus.forEach((status) => {
        queryParams.push(`status=${status}`);
      });
    }
    
    const finalQueryParams = `?${queryParams.join("&")}`;
    
    fetchEnquiries(finalQueryParams).then(() => {
      if (filterEnquiry.length === 0 && enquires.length === 0) {
        setNoDataMessage("No data found");
      } else {
        setNoDataMessage(""); // Clear the message when data is present
      }
      setIsModalOpen(false); // Close the modal after applying filters
    });
  };

  const handleResetFilters = () => {
    // Reset the filter values
    setFromDate("");
    setToDate("");
    setSelectedStatus(["Open"]);
    setFilterEnquiry([]);
    setIsModalOpen(false);
  };

  const handleEdit = (enquiryID) => {
    localStorage.setItem("action", "edit");
    localStorage.setItem("enquiryID", enquiryID);
    navigate("/enquiry", { state: { enquiryID } });
  };
  // Show the confirmation dialog and set the selected enquiry ID
  const handleDeleteClick = (enquiryID) => {
    setSelectedEnquiryID(enquiryID); // Save the ID of the enquiry to delete
    setOpenDialog(true); // Open the dialog
  };
  // Perform the delete operation when "Yes" is clicked
  const handleDeleteConfirm = () => {
    axiosConfig
      .put(`/enquiries/${selectedEnquiryID}`, { status: "deleted" }) // Update status to "deleted"
      .then((response) => {
        console.log("Enquiry marked as deleted:", response.data);
        setEnquires((prevProducts) =>
          prevProducts.filter(
            (product) => product.enquiryID !== selectedEnquiryID
          )
        );
        setOpenDialog(false); // Close the dialog after deleting
        setSelectedEnquiryID(null); // Clear the selected enquiry ID
      })
      .catch((error) => {
        console.error("Error marking enquiry as deleted:", error);
        setOpenDialog(false); // Close the dialog on error
      });
  };

  // Close the dialog when "No" is clicked
  const handleDeleteCancel = () => {
    setOpenDialog(false); // Close the dialog
    setSelectedEnquiryID(null); // Clear the selected enquiry ID
  };
 
  
  const columns = [
    {
      dataField: "id",
      text: "S.No",
      sort: true,
      headerClasses: styles["header-sn"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<AiFillInfoCircle className={styles["icon-color"]} />}
          title="S.No"
          tooltipText="Serial Number"
        />
      ),
    },
    {
      dataField: "enquiryID",
      text: "Enquiry ID",
      sort: true,
      headerClasses: styles["header-enqid"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge className={styles["icon-color"]} />}
          title="Enq ID"
          tooltipText="Enquiry ID"
        />
      ),
      formatter: (cell) => `ENQ${cell}`,
    },
    {
      dataField: "Enquiry_Title", // Ensure this matches your data field name
      text: "Enquiry Title",
      sort: true,
      headerClasses: styles["header-enqtitle"], // Use direct class name instead of styles[]
    
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdAssignment className={styles["icon-color"]} />} // Choose a relevant icon
          title="Enq Title"
          tooltipText="Enquiry Title"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis-enq"]} title={cell}>
          {cell}
        </span>
      ),
    },
    {
      dataField: "User_Quotation_Id",
      text: "Quotation ID",
      sort: true,
      headerClasses: styles["header-quotation"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsFileText className={styles["icon-color"]} />}
          title="Qut ID"
          tooltipText="Unique Quotation Identifier"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
      
    },
    
    
    
    {
      dataField: "Company_Name",
      text: "Company Name",
      sort: true,
      headerClasses: styles["header-company"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness className={styles["icon-color"]} />}
          title="Company Name"
          tooltipText="Company Name"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-comellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    },
    // {
    //   dataField: "Name",
    //   text: "Contact Person",
    //   sort: true,
    //   headerClasses: styles["header-contact"],
    //   classes: styles["cell-center-nowrap"],
    //   headerFormatter: () => (
    //     <HeaderWithTooltip
    //       icon={<MdPerson className={styles["icon-color"]} />}
    //       title="Contact"
    //       tooltipText="Contact Person"
    //     />
    //   ),
    // },
    // {
    //   dataField: "Mobile_No",
    //   text: "Mobile",
    //   sort: true,
    //   headerClasses: styles["header-mobile"],
    //   classes: styles["cell-center-nowrap"],
    //   headerFormatter: () => (
    //     <HeaderWithTooltip
    //       icon={<BsTelephone className={styles["icon-color"]} />}
    //       title="Mobile"
    //       tooltipText="Mobile Number"
    //     />
    //   ),
    // },
    // {
    //   dataField: "Email_Id",
    //   text: "Email Id",
    //   sort: true,
    //   headerClasses: styles["header-email"],
    //   classes: styles["cell-nowrap"],
    //   headerFormatter: () => (
    //     <HeaderWithTooltip
    //       icon={<BsFillEnvelopeFill className={styles["icon-color"]} />}
    //       title="Email"
    //       tooltipText="Email Id"
    //     />
    //   ),
    //   formatter: (cell) => (
    //     <span className={styles["cell-ellipsis"]} title={cell}>
    //       {cell}
    //     </span>
    //   ),
    // },


    {
      dataField: "ExpectedPrice",
      text: "Order Value",
      sort: true,
      headerClasses: styles["header-value"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsCurrencyRupee className={styles['icon-color']} />}
          title="Order Value"
          tooltipText="Order Value"
        />
      ),
    },
   

    {
      dataField: "Assigned_To",
      text: "Assigned_To",
      sort: true,
      headerClasses: "follow-assignto",
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaUser className={styles["icon-color"]} />}
          title="Assigned"
          tooltipText="Person Assigned To"
        />
      ),
      formatter: (cell) => (
        <span className={styles["cell-ellipsis"]} title={cell}>
          {cell}
        </span>
      ),
    },

    {
      dataField: "Created_Date",
      text: "Created Date",
      sort: true,
      headerClasses: styles["header-createddate"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt className={styles["icon-color"]} />}
          title="Created Date"
          tooltipText="Created Date"
        />
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerClasses: styles["header-status"],
      classes: styles["cell-center-nowrap"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdInfo className={styles["icon-color"]} />} // Replace with a relevant icon
          title="Status"
          tooltipText="Current Status of the Enquiry"
        />
      ),
      formatter: (cell) => (
        <span className={styles["status-cell"]}>{cell || "N/A"}</span>
      ),
    },
   
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: styles["header-action"],
      classes: styles["cell-nowrap-center"],
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaTasks className={styles["icon-color"]} />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => (
        <div className={styles["action-buttons"]}>
          <BsPencilFill
            className={styles["action-icon-edit"]}
            onClick={() => handleEdit(row.enquiryID)}
          />

          <BsTrash3Fill
            className={styles["action-icon-delete"]}
            onClick={() => handleDeleteClick(row.enquiryID)}
          />
        </div>
      ),
    },
  ];

  const csvHeaders = [
    { label: "S.No", key: "id" },
    { label: "Enquiry ID", key: "enquiryID" },
    { label: "Company Name", key: "Company_Name" },
    { label: "Notes", key: "Notes" },
    { label: "Assigned To", key: "Assigned_To" },
    { label: "Contact Person", key: "Name" },
    { label: "Mobile", key: "Mobile_No" },
    { label: "Email", key: "Email_Id" },
    { label: "Order Value", key: "ExpectedPrice" },
    { label: "Created Date", key: "Created_Date" },
    { label: "Status", key: "status" },
    { label: "Product Name", key: "Product_Name" },
    { label: "Model No", key: "Model_No" },
    { label: "Quantity", key: "Quantity" },
    { label: "Order Value", key: "Order_Value" },
    { label: "Description", key: "Description" },
    { label: "Enquiry Tittle", key: "Enquiry_Title" },
    { label: "User Quotation Id", key: "User_Quotation_Id" },
  ];
  

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    // sizePerPageList: [],
  });
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  return (
    <div>
      <Header />
      <div className={styles["Enquiry-box"]}>
        <div>
          <Button
            variant="primary"
            className={styles["filter-button"]}
            onClick={openModal}
          >
            <BsFilter className={styles["filter-enquiry-button-icon"]} />
            Filter
          </Button>
          {isModalOpen && (
            <div className={styles["filter-overlay"]}>
              <div className={styles["filter-container"]}>
                <div className={styles["filter-header"]}>
                  <h2 className={styles["filter-titles"]}>Filter</h2>
                  <div className={styles["filter-close"]} onClick={closeModal}>
                    ×
                  </div>
                </div>
                <form>
                  <div className={styles["filter-group"]}>
                    <label className={styles["filter-label"]}>Date Range</label>
                    <div className={styles["date-range-container"]}>
                      <div className={styles["input-container"]}>
                        <label
                          htmlFor="fromDate"
                          className={styles["input-label"]}
                        >
                          From
                        </label>
                        <input
                          type="date"
                          id="fromDate"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          className={styles["input-date"]}
                        />
                      </div>
                      <div className={styles["input-container"]}>
                        <label
                          htmlFor="toDate"
                          className={styles["input-label"]}
                        >
                          To
                        </label>
                        <input
                          type="date"
                          id="toDate"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          className={styles["input-date"]}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={styles["filter-group"]}>
                    <label className={styles["filter-label"]}>Status</label>
                    <div className={styles["status-checkbox-container"]}>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Open"
                          checked={selectedStatus.includes("Open")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Open
                      </label>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Won"
                          checked={selectedStatus.includes("Won")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Won
                      </label>
                      <label className={styles["checkbox-label"]}>
                        <input
                          type="checkbox"
                          name="status"
                          value="Lost"
                          checked={selectedStatus.includes("Lost")}
                          onChange={(e) => handleStatusChange(e)}
                        />
                        Lost
                      </label>
                    </div>
                  </div>

                  <div className={styles["modal-foot"]}>
                    <button
                      type="button"
                      onClick={handleApplyFilters}
                      className={styles["filter-bth"]}
                    >
                      Apply
                    </button>
                    <button
                      type="button"
                      onClick={handleResetFilters}
                      className={styles["filter-reset"]}
                    >
                      Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <div className={styles["but-contain"]}>
          <Button
            variant="primary"
            className={styles["add-enquiry-button"]}
            onClick={addNavigateButton}
          >
            <BsPlusCircleFill className={styles["add-enquiry-button-icon"]} />
            Add New Enquiry
          </Button>
        </div>
        
        <div className={styles["enq-table"]}>
  <BootstrapTable
    bootstrap4
    keyField="id"
    data={filterEnquiry.length > 0 ? filterEnquiry : enquires}
    columns={columns}
    pagination={pagination}
    striped
    noDataIndication={() => "No data found"} // Show this message inside the table if no data
  />
</div>



        <CSVLink
          data={filterEnquiry.length > 0 ? filterEnquiry : enquires} // Use filtered data if available
          headers={csvHeaders}
          filename={"enquiries_data.csv"}
          className="btn btn-success"
        >
          Export to CSV
        </CSVLink>
      </div>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleDeleteCancel}
        className={styles["custom-dialog"]}
      >
        <div className={styles["custom-dialog-title"]}>Confirm Delete</div>
        <DialogContent className={styles["custom-dialog-content"]}>
          Are you sure you want to delete this enquiry?
        </DialogContent>
        <DialogActions className={styles["custom-dialog-actions"]}>
          <Button
            variant="outlined"
            onClick={handleDeleteCancel}
            className={styles["custom-button-secondary"]}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteConfirm}
            className={styles["custom-button-danger"]}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
  
}

export default EnquiryManagement;
